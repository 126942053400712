<template>
  <div class="dialogWrapper">
    <!-- 选择币种 begin -->
    <div v-if="isType=='0'" class="choosedC">
      <div class="title">{{$t('menu.selectCurrency')}}</div>
      <div class="currency-container">
        <div class="list" :class="[isChoosedType==index?'choosed':'']" v-for="(item,index) in currencyList" :key="index" @click="choosedCurre(index)">
          <span>{{item}}</span>
          <img src="../../../../../public/images/lukyStar/icon-choosed.png" alt="">
        </div>
      </div>
    </div>
    <!-- 选择币种 end -->
    <!-- 申购幸运星第一步 begin -->
    <div v-if="isType=='1'&isStep1" class="exchange-box">
      <div class="dialog-exchange">
        <div class="title-exchange">{{$t('menu.dhxyx')}}<img src="../../../../../public/images/icon_close.png" @click="closeExchangeStep1" alt=""></div>
        <div class="sec-one">
          <div class="name-dialog"><span>{{$t('menu.luckyStarNumber')}}</span><button class="random-btn" @click="useRandom">{{$t('menu.systemRandomNumber')}}</button></div>
          <div class="box-input">
            <input type="number" maxlength="6" oninput="if(value.length > 6)value = value.slice(0, 6)" :placeholder="'(0-9)'+$t('menu.sixDigits')" id="" v-model="ticketNumber" @change="changeTick($event)" style="color:white">
          </div>
        </div>
        <div class="name-dialog"><span>{{$t('menu.currency')}}</span></div>
        <div class="list-a">
          <a href="javascript:;" :class="[isChoosedType2==index?'active':'']" v-for="(item,index) in currencyListType2" :key="index" @click="choosedType2(index)">
            <span>{{item}}</span>
            <img src="../../../../../public/images/lukyStar/icon-gou.png" alt="">
          </a>
        </div>
        <div class="name-dialog"><span>{{$t('menu.quantityToBeUsed')}}</span><span class="num">{{priceTicketInCake}}</span></div>
        <div><button class="btn-exchange" @click="toExchange">{{$t('menu.exchange')}}</button></div>
      </div>
    </div>
    <!-- 申购幸运星第一步 end -->
    <!-- 申购幸运星第二步 begin -->
    <div v-if="isStep2" class="exchange-box">
      <div class="dialog-exchange2">
        <div class="name-grey">{{$t('menu.luckyStarNumber')}}</div>
        <div class="numb">{{ticketNumber}}</div>
        <div class="name-dialog"><span>{{$t('menu.quantityToBeUsed')}}</span><span class="num">{{priceTicketInCake}}</span></div>
        <div class="btn-box">
          <button class="btn-exchange cancle" @click="toExchangeStep2(0)">{{$t('menu.cancel')}}</button>
          <!--授权 -->
          <button class="btn-exchange" v-if="approveNot" @click="approve()" :disabled="approveBtn" :class="{disabled_btn:approveBtn}">{{$t('menu.grantAuthorization')}}</button> 
          <button class="btn-exchange" v-if="!approveNot" @click="toExchangeStep2(1)" :disabled="buyTicketsBtn" :class="{disabled_btn:buyTicketsBtn}">{{$t('menu.exchange')}}</button>

        </div>
      </div>
    </div>
    <!-- 申购幸运星第二步 end -->
    
  </div>
</template>

<script>
import '../styles/ExchangeDialog.css';

const mLottery = require('../../../../utils/mLottery')
const starPool = require('../../../../utils/starPool')

export default {
  name: 'ExchangeDialog',
  components:{},
  props:{
    isType:{
      type:String,
      default:'0'
    },
    currencyType:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      isChoosedType:0,
      isChoosedType2:0,
      isStep1:true,
      isStep2:false,
      currencyList:['STAR'],
      // currencyList:['STAR','ZEED','YEED'],
      currencyListType2:['STAR'],
      // currencyListType2:['STAR','ZEED','YEED'],

      approveNot: true,//没有授权
      lotteryId:0,
      priceTicketInCake:0,//
      ticketNumber:'',//6位彩票前加一个1, 如果054321 -> 1054321， 其中1是第一位，2是第二位。。。0是第6位，从后往前数

      approveBtn:false,
      buyTicketsBtn:false,
      isRandom:false, //是否使用随机数
      randomNum:'', //随机数变量
    }
  },
  created() {
    this.isChoosedType = this.currencyType;
    this.createdGetData();
    
  },
  methods:{
    changeTick(event){
      if(this.ticketNumber==this.randomNum){
        this.isRandom=true;
      }else{
        this.isRandom=false;
      }
      console.log(event.target.value)
    },
    useRandom(){
      //使用系统随机数
      this.isRandom = true;
      let RandomSixStr = ''
      for(let i = 0;i < 6; i++) {
        RandomSixStr += String(Math.floor(Math.random()*10))
      }
      // return RandomSixStr;
      this.ticketNumber = Math.abs(RandomSixStr);
    },
    choosedCurre(index){
      this.isChoosedType = index;
      //第一个是弹窗点击类型（‘0’是显示底部弹窗选择币种，‘1’是点击申购幸运星按钮后显示的内容）第二个为是否关闭弹窗（0关闭 1打开）第三个为第一个弹窗选择的币种
      this.$emit('transfer',this.isType,0,this.isChoosedType); 
    },
    choosedType2(index){
      this.isChoosedType2 = index;
      //第一个是弹窗点击类型（‘0’是显示底部弹窗选择币种，‘1’是点击申购幸运星按钮后显示的内容）第二个为是否关闭弹窗（0关闭 1打开）第三个为第一个弹窗选择的币种
      console.log('choosedType2',index)

      // 切换彩票合约
      this.createdGetData()
      
    },
    closeExchangeStep1(){
      this.$emit('transfer',this.isType,0,this.isChoosedType2); 
    },
    toExchange(){
      if(!this.isRandom){
        if(this.ticketNumber.length!==6){
          this.$toast(this.$t('menu.systemLuckyTip'));
          return false
        }
      }
      this.isStep1 = false;
      this.isStep2 = true;
    },
    toExchangeStep2(type){
      if(type==0){
        this.isStep1 = true;
        this.isStep2 = false;
      }
      if(type==1){
        // 购买彩票
        if(!this.isRandom){
          if(this.ticketNumber.length!==6){
            this.$toast(this.$t('menu.systemLuckyTip'));
            return false;
          }
        }
        this.ticketNumber = this.ticketNumber+'';
        this.buyTicketsBtn = true;
        mLottery.default.buyTickets(this.lotteryId,this.ticketNumber).then(res=>{
          // console.log('购买彩票res',res)
          if(res && typeof res !== "undefined" && res !== 0){
            this.$toast(this.$t('menu.systemLuckySucTip'));
            this.$emit('transfer',this.isType,0,this.isChoosedType2); 
          }
          this.buyTicketsBtn = false;
        })
        // 购买彩票完成后
        // this.$emit('transfer',this.isType,0,this.isChoosedType2); 
      }
    },
    createdGetData(){
      mLottery.default.switchContract(this.isChoosedType2);
      let that = this;
      console.log(mLottery);
      // 当前正在进行的lotteryId
      mLottery.default.viewCurrentLotteryId().then(lotteryId=>{
        console.log(lotteryId)
        that.lotteryId = lotteryId
        mLottery.default.viewLottery(lotteryId).then(viewLotteryRes=>{
          that.priceTicketInCake = starPool.amountConversion(viewLotteryRes.priceTicketInCake)
          console.log('priceTicketInCake',that.priceTicketInCake)
        })
      })
      // 获取是否授权
      mLottery.default.allowanceTransferAmount().then(res=>{
        that.approveNot = res==0;
      })
    },
    // 授权
    approve(){
      let that = this
      that.approveBtn = true
      mLottery.default.approveTransferAmount().then(res=>{
        console.log(res)
        if(res && typeof res !== "undefined" && res !== 0){
          that.approveNot = false
        }
        that.approveBtn = false
      })
    }
  }
}
</script>

<style>

</style>